import React from 'react';
import { AnswerHistoryQuestion } from '../../../../../../interfaces/change';
import { textWrapEllipse } from '../../../../../../helpers/utils/strings';
import { Progress } from 'reactstrap';
import useMobile from '../../../../../../hooks/useBreakpoint';
import { useAnswerHistoryAverage } from './hooks/useAnswerHistoryAverage';
import { useFeedbackChartQuestionTitle } from './hooks/useFeedbackChartQuestionTitle';
import { ConversationQuestion } from '../../../../../../interfaces/conversations';
import { useAnswerHistoryItems } from './hooks/useAnswerHistoryItems';

type FeedbackChartFigureItemProps = {
  questions: ConversationQuestion[];
  question: AnswerHistoryQuestion;
};

const FeedbackChartFigureItem = ({
  question,
  questions,
}: FeedbackChartFigureItemProps) => {
  const isMobile = useMobile();
  const historyAverage = useAnswerHistoryAverage(question);
  const historyItems = useAnswerHistoryItems(question);

  const questionName = useFeedbackChartQuestionTitle(
    question.question_id,
    questions
  );

  return (
    <div
      key={question.question_id}
      className="d-flex align-items-center justify-content-between"
    >
      <div className="flex flex-col gap-1">
        <div className="d-flex align-items-center gap-2">
          <div
            style={{
              background: question.question_color,
              width: 15,
              height: 15,
              borderRadius: 15,
            }}
          />
          <span className="Feedback__question-title">
            {textWrapEllipse(questionName ?? '', 20)}
          </span>
        </div>
        <div className="flex items-center gap-2">
          {historyItems.map((item) => (
            <div className="flex flex-col items-center rounded-md bg-white px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
              <span>{item.hour}</span>
              <span className="text-lg">{item.value}</span>
            </div>
          ))}
        </div>
      </div>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ width: isMobile ? 140 : 200 }}
      >
        <Progress
          style={{
            width: isMobile ? 100 : 160,
            height: 5,
            marginRight: 15,
          }}
          barStyle={{ background: question.question_color }}
          value={historyAverage}
        />
        {question.answer_history.length > 0
          ? Math.ceil(historyAverage).toFixed(0)
          : 'N/A'}
      </div>
    </div>
  );
};

export default FeedbackChartFigureItem;
