import React from 'react';
import { UseQueryResult } from 'react-query';
import useUserQuestionHistoryInDay from '../../../../api/queries/change/useUserQuestionHistoryInDay';
import useUserCaseConversationQuestions from '../../../../api/queries/conversations/useUserCaseConversationQuestions';
import { CasesHelpers } from '../../../../helpers/data/cases';
import useAsyncEventListen from '../../../../hooks/events/useAsyncEventListen';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';
import { useQueriesLoading } from '../../../../hooks/queries/useQueriesLoading';
import { ICaseDetails } from '../../../../interfaces/cases';
import FeedbackChartFigurePlaceholder from '../FeedbackChartFigurePlaceholder';
import FeedbackChartFigureItem from './components/FeedbackChartFigureItem';

type FeedbackChartProps = {
  date: Date;
  caseDetails: UseQueryResult<ICaseDetails>;
};

const FeedbackChartFigure = ({ date, caseDetails }: FeedbackChartProps) => {
  const caseId = caseDetails.data?.id;
  const userId = caseDetails.data
    ? CasesHelpers.GetUserIdBasedOnCase(caseDetails.data)
    : null;

  const questionHistory = useUserQuestionHistoryInDay({
    params: {
      userId: userId ?? '',
      caseId: caseId ?? '',
      date: date,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  const conversationQuestions = useUserCaseConversationQuestions({
    params: {
      userId: userId ?? '',
      caseId: caseId ?? '',
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(caseDetails);
  useInitQuery(questionHistory, !!userId && !!caseId);
  useInitQuery(conversationQuestions, !!userId && !!caseId);

  useAsyncEventListen(
    'feedback-time-refetch-requested',
    async () => {
      await questionHistory.refetch();
    },
    true
  );

  const isLoading = useQueriesLoading([
    caseDetails,
    questionHistory,
    conversationQuestions,
  ]);

  if (isLoading) return <FeedbackChartFigurePlaceholder />;

  console.log('questions data', questionHistory.data?.questions ?? []);

  return (
    <div className="d-flex flex-column gap-3 mt-4">
      {questionHistory.data?.questions.map((question) => (
        <FeedbackChartFigureItem
          key={question.question_id}
          question={question}
          questions={conversationQuestions.data ?? []}
        />
      ))}
    </div>
  );
};

export default FeedbackChartFigure;
