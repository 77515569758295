import { AnswerHistoryQuestion } from '../../../../../../../interfaces/change';
import { useMemo } from 'react';
import { format } from 'date-fns';

export type AnswerHistoryLabelItem = {
  hour: string;
  value: string;
};

export const useAnswerHistoryItems = (question: AnswerHistoryQuestion) => {
  return useMemo(() => {
    return question.answer_history.map((item) => ({
      hour: format(new Date(item.date), 'HH:mm'),
      value: item.value === undefined ? '--' : item.value,
    }));
  }, [question.answer_history]);
};
